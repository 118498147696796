import React, { useRef, useState, useEffect } from "react"
import cx from "classnames"
import ScrollReveal from "scrollreveal"
import CountUp from "react-countup"
import loadable from "@loadable/component"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { IMG } from "../const"
import "../assets/styles/commons/lists/_common__list__animatedCounters.scss"
import "../assets/styles/commonsGlossy.scss"
import * as styles from "../assets/styles/software-development.module.scss"
import { ANIMATED_COUNTERS } from "../data/software-developmentCounters"
import {
  CUSTOMERS,
  SOFTWARE__DEVELOPMENT_SERVICES,
  TECHNOLOGIES,
} from "../components/software-development/const"
import CardsCarousel from "../components/carousels/CardsCarousel"

const CaseStudies = loadable(() => import("../components/case-studies"))
const Accordion = loadable(() => import("../components/accordion"))
const ContactsForm = loadable(() =>
  import("../components/contact-form/contacts")
)

const SoftwareDevelopment = () => {
  const [startCount, setStartCount] = useState(false)
  const countAnimationDuration = 3
  const counterBlock = useRef(null)

  useEffect(() => {
    const handleScroll = e => {
      const counterBlockOffset = counterBlock.current.getBoundingClientRect()
      const counterBlockPosition = counterBlockOffset.top - 200
      if (!startCount && counterBlockPosition < 0) {
        setStartCount(true)
      }
    }

    document.addEventListener("scroll", handleScroll, { passive: true })
    return function cleanup() {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [startCount])

  useEffect(() => {
    ScrollReveal().reveal(`#Customers li`, {
      duration: 1500,
      useDelay: "onload",
      interval: 50,
      delay: 50,
    })
    ScrollReveal().reveal(`#Techstack li`, {
      duration: 1500,
      useDelay: "onload",
      interval: 50,
      delay: 50,
    })
  }, [])

  return (
    <Layout pageTitle="Software Development">
      {({ width }) => {
        return (
          <>
            <main className={cx(styles.softwareDevelopmentBlock, "glossy")}>
              <article className="commonGlossy__article">
                <div className={styles.softwareDevelopmentBlock__headline}>
                  <h1 className="common__textOutline">
                    A software development <strong>partner you</strong>{" "}
                    can&nbsp;<strong>trust</strong>
                  </h1>
                  <p className={cx(styles.buttonContainer, styles.onDesktop)}>
                    <Link
                      to="#contact-form"
                      className="goldFatLink software-talk"
                    >
                      Let’s talk
                    </Link>
                  </p>
                  <p>
                    <mark>
                      Team up with professionals so that you can enjoy the
                      process and the results
                    </mark>
                  </p>
                  <p className={cx(styles.buttonContainer, styles.onMobile)}>
                    <Link
                      to="#contact-form"
                      className="goldFatLink software-talk"
                    >
                      Let’s talk
                    </Link>
                  </p>
                  <p>
                    LaSoft has been a trusted technical partner&nbsp;for
                    international brands since&nbsp;2014.
                  </p>
                </div>

                <section
                  className={cx(
                    styles.softwareDevelopmentBlock__facts,
                    "commonGlossy__article__section indentTop1o2 indentBot1o6"
                  )}
                  ref={counterBlock}
                >
                  <p>
                    <mark
                      className={cx(
                        styles.softwareDevelopmentBlock__mmmdash,
                        "common__textOutline"
                      )}
                    >
                      We rely on technological expertise, a great team culture,
                      and <strong>streamlined processes</strong> to deliver
                      world-class custom software products.
                    </mark>
                  </p>
                  <ul
                    className={cx(
                      styles.softwareDevelopmentBlock__numbers,
                      "common__list__animatedCounters"
                    )}
                  >
                    {ANIMATED_COUNTERS.map(item => (
                      <li key={item.id}>
                        <strong>
                          {startCount && (
                            <CountUp
                              start={item.start}
                              end={item.end}
                              delay={item.delay}
                              duration={countAnimationDuration}
                              useEasing={false}
                              suffix={item.suffix ? item.suffix : ""}
                            >
                              {({ countUpRef }) => <ins ref={countUpRef} />}
                            </CountUp>
                          )}
                        </strong>
                        <span>{item.text}</span>
                      </li>
                    ))}
                  </ul>
                  <p
                    className={cx(
                      styles.softwareDevelopmentBlock__accompany__leadQuote,
                      "common__leadQuote"
                    )}
                  >
                    <em className="fs1o5">
                      We lead clients to digital success by providing customized
                      software solutions to customers from more than
                      10&nbsp;countries.
                    </em>
                  </p>
                  <ul
                    className={styles.softwareDevelopmentBlock__customersList}
                    id="Customers"
                  >
                    {CUSTOMERS.map(customer => (
                      <li key={customer.name}>
                        <img
                          src={`${IMG.ABOUT}/${customer.image}.svg`}
                          alt={customer.name}
                          width="138"
                          height="48"
                          loading="lazy"
                        />
                      </li>
                    ))}
                  </ul>
                </section>
                <section
                  className={cx(
                    styles.softwareDevelopmentBlock__leadingBusiness,
                    "commonGlossy__article__section indent1o6 upTo2cols"
                  )}
                >
                  <h2 className="at640__pr50p">
                    Leading businesses to achievement
                  </h2>
                  <div
                    className={
                      styles.softwareDevelopmentBlock__leadingBusiness__summary
                    }
                  >
                    <p>
                      <mark>
                        We’ve helped over 85&nbsp;companies build scalable
                        products with confidence. Our teams are trusted by CTOs
                        from Los&nbsp;Angeles to Dubai thanks to their proven
                        skills in 10+&nbsp;markets!
                      </mark>
                    </p>
                    <p>
                      With almost 10&nbsp;years of software development
                      experience, our teams combine deep industry knowledge with
                      comprehensive technical expertise.
                    </p>
                  </div>
                  <div
                    className={
                      styles.softwareDevelopmentBlock__leadingBusiness__tech
                    }
                  >
                    <ul
                      className={
                        styles.softwareDevelopmentBlock__leadingBusiness__techLogos
                      }
                      id="Techstack"
                    >
                      {TECHNOLOGIES.map(technology => (
                        <li key={technology.name}>
                          <figure>
                            <img
                              src={`${IMG.ICONS}/logos/${technology.icon}`}
                              width="32"
                              height="32"
                              loading="lazy"
                              alt={technology.name}
                            />
                            <figcaption>{technology.name}</figcaption>
                          </figure>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <p
                    className={cx(
                      styles.softwareDevelopmentBlock__leadingBusiness__capture,
                      "fs1o5"
                    )}
                  >
                    <em>
                      <strong>
                        We use advanced technologies to help corporate clients,
                        and businesses of all sizes to deliver their digital
                        projects on time and within an agreed budget.
                      </strong>
                    </em>
                  </p>
                </section>
                <section className={styles.softwareDevelopmentBlock__dev}>
                  <div className="commonGlossy__article__section indentTop1o6 indentBot0">
                    <h2>Software Development Services</h2>
                  </div>
                  <div
                    className={
                      styles.softwareDevelopmentBlock__dev__carouselPlaceholder
                    }
                  >
                    <CardsCarousel screenWidth={width} />
                  </div>
                </section>

                <div className="commonGlossy__article__case-studies">
                  <CaseStudies title="" screenWidth={width} />
                </div>

                <section className="commonGlossy__article__section indentBot1o2">
                  <h2 className="at1024__pr50p">
                    LaSoft&nbsp;— your one‑stop shop technology partner:
                  </h2>
                  <Accordion options={SOFTWARE__DEVELOPMENT_SERVICES} />
                </section>
                <section className="commonGlossy__article__section indentTop1o2">
                  <div className="common__teaser v2022">
                    <div>
                      <h4 className="maxw20">
                        Try new ways of working powered
                        by&nbsp;trusted&nbsp;partnerships.
                      </h4>
                    </div>
                    <div className="common__teaser__button">
                      <Link
                        to="#contact-form"
                        className="goldFatLink darkBackground software-client"
                      >
                        Become a client
                      </Link>
                    </div>
                  </div>
                </section>
              </article>
            </main>
            <section className="footerForm">
              <div id="contact-form">
                <ContactsForm formStatID="CommonFooter" />
              </div>
            </section>
          </>
        )
      }}
    </Layout>
  )
}

export default SoftwareDevelopment
